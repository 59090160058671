.ErrorMsg {
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 95%;
  height: 50px;
  background-color: var(--highlight-color);
  border: 1px solid var(--accent-color);
  border-radius: 5px;
  margin: 10px 0;
  padding: 12px;
  position: relative;
  opacity: 1;
  transition: all 1s ease-in-out;
}

.Invisible {
  width: 95%;
  opacity: 0;
  height: 0;
  pointer-events: none;
  transition: all 0.5s ease-in-out;
}

.ErrorMsg span {
  width: 100%;
  text-align: center;
  color: var(--accent-color);
  font-weight: bold;
}

.ErrorMsg button {
  width: auto;
  height: auto;
  color: var(--accent-color);
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  top: -13px;
  right: 0;
}

.ErrorMsg button:hover,
.ErrorMsg button:active {
  background-color: rgba(0, 0, 0, 0);
}

@media (min-width: 500px) {
  .ErrorMsg {
    width: 70%;
    max-width: 500px;
  }

  .Invisible {
    width: 70%;
    max-width: 500px;
  }
}
