.Content {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 70%;
}

.Content form {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Content form input {
  margin-top: 15px;
  margin-bottom: 5px;
  border: 2px solid var(--accent-color);
  border-radius: 6px;
  height: 35px;
  padding: 2px 20px;
  font-size: 20px;
  background: #0000;
  color: var(--accent-color);
  text-align: center;
  width: 90%;
  max-width: 300px;
}

.Content h1 {
  padding-left: 3%;
  font-size: 25px;
}

.Content > button {
  margin: 3px;
  font-weight: bold;
  font-size: 15px;
  width: 50px;
  height: 25px;
  background: var(--accent-color);
  color: var(--background-color);
}

.Content > button:hover {
  background: var(--highlight-color);
  color: var(--accent-color);
}

.SideNavbar {
  text-align: center;
  width: 30%;
}

.Content ul {
  list-style: none;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Content ul li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  max-width: 350px;
}

.Content ul li > div {
  max-width: 230px;
}

.Content ul li button {
  width: 120px;
  height: 35px;
  background: var(--highlight-color);
  color: var(--accent-color);
}

.Content ul li button:hover {
  background: var(--accent-color);
  color: var(--background-color);
}

@media (min-width: 500px) {
  .Content {
    padding-left: 12%;
  }

  .Content h1 {
    font-size: 30px;
  }
}
