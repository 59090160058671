.ToggleSwitch {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.ToggleSwitch label {
  width: 190px;
}
