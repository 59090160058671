.Logo {
  mask-image: url('../../../assets/logo_512.png');
  background-color: var(--accent-color);
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  height: 55px;
  width: 64px;
  box-sizing: border-box;
}

@media (min-width: 500px) {
  .Logo {
    height: 45px;
    width: 64px;
  }
}
