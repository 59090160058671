.loginContainer {
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  width: 100%;
}

.loginContainer input {
  width: 300px;
  margin-bottom: 5px;
  border: 2px solid var(--accent-color);
  border-radius: 6px;
  height: 35px;
  padding: 5px;
  font-size: 20px;
  background: #0000;
  color: var(--accent-color);
  text-align: center;
}

.loginContainer label {
  width: 300px;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--contrast-color);
}

.loginContainer form button {
  width: 39%;
  height: 35px;
  font-size: 20px;
}

.loginContainer p {
  color: var(--contrast-color);
}

.logo {
  height: 190px;
  width: 95%;
  align-self: center;
  background-color: var(--accent-color);
  background-image: url('../../assets/logo_mask.png');
  mask-image: url('../../assets/logo_small.png');
  background-position: center;
  mask-position: center;
  background-size: contain;
  mask-size: contain;
  background-repeat: no-repeat;
  mask-repeat: no-repeat;
}

@media (min-width: 500px) {
  .logo {
    height: 256px;
    width: 512px;
  }
}
