.MainDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
}

.MainDiv label {
  width: 190px;
}

.OuterDiv {
  padding: 5px;
  background-color: #fff;
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  display: inline-block;
  cursor: pointer;
}

.Popover {
  position: absolute;
  z-index: 2;
  transform: translate(-55%);
  transition: all ease-in-out 0.4s;
}

.ColorPickerDiv {
  position: fixed;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
}

@media (min-width: 530px) {
  .Popover {
    transform: translate(0);
  }
}
