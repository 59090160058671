.Content {
  width: 70%;
  padding-top: 10px;
  padding-left: 2%;
}

.Content h1 {
  padding-left: 3%;
  font-size: 25px;
}

.Content form {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Content form select {
  margin-top: 15px;
  margin-bottom: 5px;
  border: 2px solid var(--accent-color);
  border-radius: 6px;
  height: 35px;
  padding: 2px 20px;
  font-size: 20px;
  background: #0000;
  color: var(--accent-color);
  text-align-last: center;
  width: 90%;
  max-width: 300px;
}

.Content form label {
  font-size: 20px;
  margin-top: 20px;
  width: 90%;
  max-width: 300px;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Content form > button {
  margin-top: 15px;
  font-size: 20px;
  height: 40px;
  width: 70%;
}

@media (min-width: 500px) {
  .Content {
    padding-left: 12%;
  }

  .Content h1 {
    font-size: 30px;
  }

  .Content form > button {
    width: 30%;
  }
}
