.Content {
  width: 100%;
  padding-top: 100px;
}

.Buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Buttons button {
  margin: 15px 5px;
  font-size: 20px;
  text-align: center;
  vertical-align: middle;
  height: 35px;
}
