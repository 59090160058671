.NavbarItem {
  margin: 10px 0;
  box-sizing: border-box;
  display: block;
  width: 100%;
}

.NavbarItem a {
  color: var(--accent-color);
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  display: block;
}

.NavbarItem a:hover,
.NavbarItem a:active,
.NavbarItem a.active {
  color: white;
  text-decoration: underline;
}

@media (min-width: 500px) {
  .NavbarItem {
    margin: 0;
    display: flex;
    height: 100%;
    width: auto;
    align-items: center;
  }

  .NavbarItem a {
    color: solid var(--accent-color);
    height: 100%;
    padding: 16px 10px;
    border-bottom: 4px solid transparent;
  }

  .NavbarItem a:hover,
  .NavbarItem a:active,
  .NavbarItem a.active {
    background-color: var(--highlight-color);
    border-bottom: 4px solid var(--accent-color);
    color: var(--contrast-color);
    text-decoration: none;
  }
}
