@import url('https://fonts.googleapis.com/css?family=Titillium+Web:400,700&display=swap');

:root {
  --accent-color: #00a8ff; /*Cor única e chamativa*/
  --background-color: #161618; /*Cor de fundo*/
  --highlight-color: #292932; /*Cor levemente distinta da de fundo*/
  --contrast-color: #bbbbca; /*Cor fortemente distinta da de fundo*/
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

body {
  background: var(--background-color);
  color: var(--accent-color);
  font-family: 'Titillium Web', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
input,
button {
  font-family: 'Titillium Web', sans-serif;
}

button {
  margin-top: 10px;
  text-align: center;
  border: 0;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
  height: 25px;
  font-size: 15px;
  background: var(--accent-color);
  color: var(--background-color);
  opacity: 0.7;
  padding: 0 5px;
}

button:hover,
button:active {
  background: var(--highlight-color);
  color: var(--accent-color);
}
